.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 40s linear;
  height: 50vmin;
  transition: height 3s ease-in-out;
}

.App-logo:hover {
  animation: App-logo-spin infinite 5s linear;
  height: 60vmin;
}

.App-header {
  background-color: rgba(242, 244, 247, .25);
  font-family: 'Prompt';
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(147, 214, 226);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h2 {
  color: white;
  font-size: 40px;
  text-shadow: -1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000;
}

h3 {
  color: white;
  font-size: 32px;
  text-shadow: -1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000;
}

h4 {
  color: white;
  font-size: 26px;
  text-shadow: -1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000;
}

.submitForm {
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
}

.buttonDiv {
  width: 15vw;
  display: flex;
  justify-content: space-between;
}

.carimg {
  max-width: 100%;
  border-radius: 5px;
}

.upVote {
  margin: 5px;
  font-size: 32px;
}

.downVote {
  margin: 5px;
  font-size: 32px;
}

.logout {
  margin: 5px;
}

.login {
  margin: 5px;
}

.loginErrorDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginError {
  font-family: Prompt;
  margin: 5px;
  color: red;
}

.builderProf {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
}

.carCard {
  width: 60vw;
  background-color: black;
}

.emptyCar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60vw;
  background-color: black;
}

.carname {
  color: white;
  font-size: 40px;
  text-shadow: -1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000;
}

.singleCar {
  background-color: black;
  margin: 5px;
  width: 400px;
}

.singleAllCar {
  background-color: black;
  margin: 5px;
  width: 50vw;
}

.overlay {
  margin: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.overlay h3 {
  color: white;
  font-size: 40px;
  text-shadow: -1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000;
}



.carDiv {
  /* display: flex; */
  flex-wrap: flex;
  flex-direction: row;
}

.userCard {
  background-color: black;
  width: 50vw;
  color: white;
  font-family: Prompt;
}

.builderProf h2 {
  font-size: 44px;
  font-family: Prompt;
  margin-top: 25px;
}

.addCarButton {
margin: 10px;
}

@media (max-width: 650px) {
  .carCard {
    width: 100vw;
  }
  .buttonDiv {
    width: 25vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .userCard {
  background-color: black;
  width: 90vw;
  color: white;
  font-family: Prompt;
  }
  .loginError {
    font-family: Prompt;
    margin: 5px;
    color: red;
    font-size: 24px;
  }
  h2 {
    color: white;
    font-size: 28px;
    text-shadow: -1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000;
  }

  .builderProf h2 {
    font-size: 26px;
  }

  .builderProf h3 {
    font-size: 24px;
  }

  h3 {
    color: white;
    font-size: 22px;
    text-shadow: -1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000;
  }

  h4 {
    color: white;
    font-size: 20px;
    text-shadow: -1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000;
  }
}
